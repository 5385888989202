import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | GleamCraft'ın Hikayesine Dalış
			</title>
			<meta name={"description"} content={"Otomotiv zarafetinin evrimine yolculuk - GleamCraft, arabanızın hikayesinin yeniden başladığı yer\n"} />
			<meta property={"og:title"} content={"Hakkımızda | GleamCraft'ın Hikayesine Dalış"} />
			<meta property={"og:description"} content={"Otomotiv zarafetinin evrimine yolculuk - GleamCraft, arabanızın hikayesinin yeniden başladığı yer\n"} />
			<meta property={"og:image"} content={"https://antalyaazure.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<link rel={"apple-touch-startup-image"} href={"https://antalyaazure.com/images/decorated-car.svg"} />
			<meta name={"msapplication-TileImage"} content={"https://antalyaazure.com/images/decorated-car.svg"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://antalyaazure.com/images/2.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
					Hakkımızda
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
					GleamCraft Auto Detailing bir stüdyodan daha fazlasıdır - araçlarını bir ifade tuvali olarak görenler için bir sığınaktır. Özümüz, otomotiv güzelliğinin kutlanmasında ve markası veya modeli ne olursa olsun her otomobilin eşsiz bir parlaklıkla ışıldamayı hak ettiği inancında yatmaktadır. Otomobilinizin hikayesinin ortaya çıktığı ve yükseldiği GleamCraft'a adım atın.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://antalyaazure.com/images/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					GleamCraft'ın Kalp Atışı
					<br />
					<br />
					Otomotivde mükemmellik tutkusuyla kurulan GleamCraft Oto Detaylandırma, hassasiyet ve adanmışlığın ritmiyle atıyor. Stüdyomuz, detaylandırmanın her aracın kendine has özelliklerinin ön plana çıkarıldığı ve tekerlekler üzerinde bir şaheser yaratan bir sanat formu olduğu inancının bir tezahürüdür.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						Özümüz Neden Önemlidir?
						<br />
						<br />
						- Detayda Sanat: GleamCraft'ta detaylandırma sadece bir hizmet değil, sanatsal bir ifade biçimidir. Ustalarımız aracınızın estetik çekiciliğini artırmak için titizlikle çalışarak onu yuvarlanan bir sanat eserine dönüştürür.
						<br />
						- Güzelliği Korumak: GleamCraft, yüzeyin ötesinde aracınızın uzun ömürlülüğünü korumayı taahhüt eder. Detaylandırma işlemlerimiz yalnızca görünümü iyileştirmekle kalmaz, aynı zamanda hava koşullarına karşı koruyucu önlemler de sağlar.
						<br />
						- Kişiselleştirilmiş Bakım: Aracınız bizim için sadece bir araç değil, benzersiz bir karaktere sahip bir bireydir. Kişiye özel detaylandırma paketlerimiz, her aracın hak ettiği bakımı almasını sağlar.
					</Text>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://antalyaazure.com/images/3.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Image src="https://antalyaazure.com/images/5.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Aracınızın Hikayesini Oluşturmak
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						GleamCraft'ta otomotiv zarafetinin anlatısında bize katılın. Stüdyomuza adım attığınızda, otomobilinizin ışıltısının sadece yüzeyini değil, yolculuğunu ve karakterini de yansıttığı bir hikayenin parçası olursunuz.
					</Text>
					<Button
						background="#0438cd"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Kişiler
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});